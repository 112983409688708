import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { SiThemeService, ThemeType } from '@simpl/element-ng';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {constant} from 'src/app/constants/constants';

@Injectable({ providedIn: "root" })
export class SettingsService {
  getLanguage(): any {
	  throw new Error("Method not implemented.");
  }
  readonly availableLanguages = ["en", "de", "fr"];
  protected initialized$ = new ReplaySubject<boolean>(1);
  themeSubject = new BehaviorSubject<any>(null);
  public onThemeChange = this.themeSubject.asObservable();
  navBarThemeSubject = new BehaviorSubject<any>(true);
  public onNavBarThemeChange = this.navBarThemeSubject.asObservable();
  private initializedSubject = new BehaviorSubject<boolean>(false);
  constructor(
    private translate: TranslateService,
    private themeService: SiThemeService
  ) {
    translate.setDefaultLang("en");
    translate.use("en");
    translate.onLangChange.subscribe(this.storeLanguage);

    const language = this.loadLanguage() ?? translate?.getBrowserLang() ?? "en";
    this.setLanguage(language);
    this.setTheme(this.loadTheme() ?? "dark");
    console.log("latest theme ", this.loadTheme() ?? "dark");
  }

  get language() {
    return this.translate.currentLang;
  }

  get theme() {
    return this.loadTheme() ?? "auto";
  }

  setLanguage(language: string) {
    this.translate.use(language);
  }

  setTheme(theme: ThemeType) {
    console.log("theme change---", theme);
    this.themeService.applyThemeType(theme);
    this.storeTheme(theme);
  
  }

  setThemeThroughNavbar(theme:any){
      this.themeSubject.next(theme);
      this.initialized$.next(true);
  }
  setNavBarThemeChange(value:any){
	this.navBarThemeSubject.next(value);
  }

  public storeLanguage(event: LangChangeEvent) {
    localStorage.setItem("lang", event.lang);
  }

  public storeTheme(theme: ThemeType) {
    localStorage.setItem("theme", theme);
  }

  public loadLanguage() {
    return localStorage.getItem("lang");
  }

  public loadTheme() {
    return localStorage.getItem("theme") as ThemeType | undefined;
  }
  checkAutoTheme(){
    let defaultMode =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    return defaultMode;
  }
  getInitializedSubject() {

    return this.initializedSubject;

  }
  convertIsoToDate(isoDateString: string,timerequired:boolean=false): any {
	//return new Date(isoDateString);
	const date = new Date(isoDateString);
	if(!timerequired){
		const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits
		const monthNames = constant.monthNames;
		const month = monthNames[date.getMonth()]; // Get full month name
		const year = date.getFullYear(); // Get full year
		const hour = date.getHours().toString().padStart(2, '0'); // Ensure two digits
		const minute = date.getMinutes().toString().padStart(2, '0'); // Ensure two digits
		const second = date.getSeconds().toString().padStart(2, '0'); // Ensure two digits
		return timerequired ?`${day}-${month}-${year} ${hour}:${minute}:${second}`: `${day}-${month}-${year}`;
	}
	else{
		const options: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: 'short',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			timeZoneName: 'short'
		  };
		  return date.toLocaleString('en-US', options);
	}
	
  }
  
}
