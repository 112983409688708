import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule,  CUSTOM_ELEMENTS_SCHEMA,APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  SI_COPYRIGHT_DETAILS,
  SiActionDialogModule,
  SiActionDialogService,
  SiFormModule,
  SiNavbarModule,
  SiSearchBarModule,
  SiSelectModule,
  SiModalService
} from '@simpl/element-ng';
import { SiDashboardModule } from '@simpl/element-ng/dashboard';
import { SiTranslateNgxTModule } from '@simpl/element-ng/ngx-translate';

import { AppComponent } from './app.component';
import { LoginComponent } from "./pages/login/login.component";
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { OAuthModule,  } from "angular-oauth2-oidc";
import { environment } from "../environments/environment";
import { Localization } from './services/localization.service';



import {
  SiLoginAuthModule,
  SiPermissionService,
  SiThemingService,
  SiAppDataService,
  SiAODSModule,
  //SiAODSService,
  SiAppListService,
  SiUOModule,
  SiSubscriptionsModule,
  SiAODSService,
  SiSharedModule,
  ServiceWorkerAuth
} from "@building-x/common-ui-ng";

import { SessionService } from "./services/session.service";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { LogoutPageComponent } from './pages/logout/logout.component';
import { AuthGuardService } from './services/auth-guard.service';



const initializeSrvWorker = (siModalService: SiModalService): Promise<void> => {
	const redirectOnAuthUrl = location.href.includes('ptsidebar') ? location.href : undefined;
  
	return new Promise(async (resolve, reject) => {
	  const loginData = {
		openIdConnectProvider: environment.issuer,
		clientId: environment.clientId,
		logoutBaseUrl: 'https://login-qa.siemens.com',
		loginRedirectUrl: redirectOnAuthUrl,
		customerIdpBaseUrl: environment.userOrganizationsApiUrl,
		extras: {
		  audience: environment.bxAudience
		},
		tokenExpiryAlertModalTime: 300
	  };
	  const swVariables = {
		oidcDomains: [environment.issuer, environment.msIssuer]
	  };
	  try {
		await new ServiceWorkerAuth(siModalService).loadServiceWorker(loginData, swVariables);
		resolve();
	  } catch (error) {
		reject(error);
	  }
	});
  };
  

const routes: Routes = [
  { path: "logout", component: LogoutPageComponent },
  { path: "login", component: LoginComponent },
  {
    path: "main",
    canActivate:[AuthGuardService],
    loadChildren: () =>
      import("./pages/main/main.module").then((m) => m.MainModule),
  },
  { path: "**", redirectTo: "main" },
];

const buildTime = "1714973808131";
export const httpLoaderFactory = (http: HttpClient) => new MultiTranslateHttpLoader(http, [
    { prefix: './assets/i18n/', suffix: `.json?version=${buildTime}` },
    { prefix: './assets/i18n/no-translations/', suffix: `.json?version=${buildTime}` },
	{ prefix: './assets/i18n/imported/no-translations/', suffix: `.json?version=${buildTime}` },
	{ prefix: './assets/i18n/imported/', suffix: `.json?version=${buildTime}` }
]);
 
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, 'assets/i18n/', '.json');
 

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserAnimationsModule,
    HttpClientModule,
    SiActionDialogModule,
    SiDashboardModule,
    SiFormModule,
    SiSearchBarModule,
    SiSelectModule,
    NgSelectModule,
    ReactiveFormsModule,
    SiLoginAuthModule,
    RouterModule.forRoot(routes, { useHash: true }),
    SiTranslateNgxTModule,
    TranslateModule,
    SiNavbarModule,
    SiSharedModule,
    OAuthModule.forRoot(),
    SiUOModule.forRoot({
      apiUrl: environment.apiUrlV2,
      product: environment.product,
      noGoogleApi: environment.test,
    }),
    SiSubscriptionsModule.forRoot({
      apiUrl: environment.subscriptionUrl,
      //apiUrlV2: environment.subscriptionUrl,
      product: environment.product,
      noGoogleApi: environment.test,
    }),
    SiAODSModule.forRoot({
      apiUrl: `${environment.aodsUrl}`,
    }),
    // AgmCoreModule.forRoot(),
    TranslateModule.forRoot({
		loader: {
			provide: TranslateLoader,
			useFactory: createTranslateLoader,
			deps: [HttpClient]
		}
	}),

	TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient]
			}
		}),
	TranslateModule.forChild({
		loader: {
			provide: TranslateLoader,
			useFactory: httpLoaderFactory,
			deps: [HttpClient]
		}
	}),

  ],
  providers: [
	ServiceWorkerAuth,
    SiActionDialogService,
    {
      provide: SI_COPYRIGHT_DETAILS,
      useValue: {
        startYear: 2023,
      },
    },
    SiPermissionService,
    SiPermissionService,
    SiAppDataService,
    SiAODSModule,
    //SiAODSService,
    Localization,
    SiAppListService,
    SiAODSService,
    // ModalModule.forRoot() forgot to provide this
    SiThemingService,
    {
		provide: APP_INITIALIZER,
		useFactory: () => initializeSrvWorker,
		multi: true,
		deps: [ServiceWorkerAuth, SiModalService]
  
    },
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(sessionService: SessionService) {
    sessionService.initialize();
  }
}

