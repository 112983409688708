import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataMovementService {
  timeZoneDataLengthSubject = new Subject<number>();
  timeZoneDataLength(number: number): void {
    this.timeZoneDataLengthSubject.next(number);
  }
}
