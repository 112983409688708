<!-- <si-landing-page
  heading="Sm@rtGear™ IE Compliance"
  [subtitle]="''"
  [links]="[
    {title: 'Corporate information', href: 'https://www.siemens.com/corporate-information'},
    {title: 'Privacy policy', href: 'https://www.siemens.com/privacy-notice'},
    {title: 'Terms of use', href: 'https://www.siemens.com/terms-of-use'}
  ]"
  [availableLanguages]="availableLanguages"
  [backgroundImageUrl]="'assets/images/xcelerator_landing.webp'"
>
  <div class="row">
    <div class="col" style="position: relative; margin-top: 10%">
      <si-form-item
        style="position: absolute; bottom: 5%"
        [label]="'System Name'"
        [labelWidth]="150"
        [required]="true"
      >
        <div class="form-control-has-icon w-100">
          <input
            type="text"
            id="name"
            class="form-control"
            minlength="3"
            value="Compliance"
            [formControl]="systemName"
            readonly="true"
          />
          <div *ngIf="systemName?.touched &&  systemName?.errors &&
          systemName?.errors!['forbiddenName'] &&
          systemName?.errors!['forbiddenName'].value !== ''"
          class="invalid-feedback mt-4">
          special characters are not allowed.
        </div>
          <div class="invalid-feedback"></div>
        </div>
      </si-form-item>
    </div>
    <div class="col" style="position: relative; margin-top: 10%">
      <div class="position-relative time-zone">
        <label for="" class="labelTimeZone mr-3">*Timezone</label>
        <span></span>
        <span></span>
        <si-select
          labelledby="inline"
          [formControl]="timeZonesDropdown"
          [options]="optionsList"
          [(value)]="value"
          (valueChange)="selectionChanged($event)"
        ></si-select>
      </div>
    </div>
  </div>

  <button
    type="submit"
    class="btn btn-primary w-100"
    (click)="saveSettings()"
  >
 Save Settings
  </button>
</si-landing-page> -->

<si-login-page data-cy="login-page"
	[heading]="'HEADING' | translate" [warningText]="'LOGIN.WARNING_TEXT' | translate" 
	backgroundImageUrl="../../../assets/images/buildingx_login_bg.png"
	[availableLanguages]="availableLanguages"  
	[subtitle] ="'SUBTITLE' | translate"
	[warningText]="warningText | translate"
	[mfaDocLink]="mfaDocLink"
	[mfaStartDate]="mfaStartDate" 
	[mfaEndDate]="mfaEndDate">
</si-login-page>

