// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiMode: "cloud",
	test: false,
	useHash: false,
	licenseApiUrl: "https://subscription.horizonint.cloud/api/v1",
	clientId: "QUvff2xkUnSGPCOXCjKZ1vnrv5odG1Cf",
	cookieUrl: "//assets.adobedtm.com/5dfc7d97c6fb/9f2b9af520c3/launch-b25a29efa7a1-staging.min.js",
	issuer: "https://siemens-qa-bt-015.eu.auth0.com/",
	authProfileUrl: "",
	msIssuer: "https://btprodeu.piam.eu1.mindsphere.io/oauth/token",
	apiUrl: "https://user.horizonint.cloud/api/v1",
	apiUrlV2: "https://prodint.um.user.horizonint.cloud/api/v2",
	subscriptionUrl: "https://product-int.subscription.horizonint.cloud/api/v2",
	audience: "https://horizonint.cloud",
	tenant: "main-tenant-oidc",
	profileUrl: "https://uss.login-int.siemens.com/userprofile",
	product: "ComplianceAssist",
	appSwitcherSource:"https://product-int.webapp.horizonint.cloud/api/v1/me/apps",
	siemensIdBaseUrl: "https://login-qa.siemens.com",
	accountManagerAppUrl: "https://account.apps.horizonint.cloud",
	assetManagerAppUrl: "https://assets.apps.horizonint.cloud",
	aodsUrl: "https://product-int.aods.horizonint.cloud/api/v2",
	googleMapsApiKey: "AIzaSyA-sSzcLt_aaEuIEblwzQrDnFifJhucpXM",
	googleAPIChannel: "106",
	isChinaDeployment: "true",
	aMapApiKey: "146f101e824accd6956eeec4937c1a05",
	locationServiceUrl: "https://product-int.locations.horizonint.cloud/v2",
	partitionId: "01d4c7a8-babc-4e8b-bf54-ae833d601f4c",
	apiBaseUrl: "https://api.complianceassist.horizonint.cloud/compassist/v1",
	userOrganizationsApiUrl: 'https://prodint.user.horizonint.cloud/api/v1',
	mfaDocLink: 'https://overseer1.erlm.siemens.de/loginsupport/Siemens%20ID%202-Factor%20Authentication%20for%20External%20Users.pdf',
	mfaStartDate: '2024-04-23',
	mfaEndDate: '2024-08-31',
	deviceUrl: 'https://product-int-dm.device.horizonint.cloud/api/v2',
	whiteListedApis: [
		'https://user.horizonint.cloud',
		'https://um.user.horizonint.cloud/api/v2',
		'https://subscription.horizonint.cloud/api/v1',
		'https://subscription.horizonint.cloud/api/v2',
		'https://int.webapp.horizonint.cloud/api/v1/me/apps',
		'https://site.horizonint.cloud',
		'https://aods.horizonint.cloud/api/v2',
		'https://platform.notification.horizonint.cloud/api/v1',
		'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
		'https://uss.login-qa.siemens.com/userprofile',
		'https://product-int.webapp.horizonint.cloud',
		'https://prodint.um.user.horizonint.cloud',
		'https://subscription.horizonint.cloud/api/v1',
		'https://siemens-qa-bt-015.eu.auth0.com/',
		'https://user.horizonint.cloud/api/v1',
		'https://prodint.um.user.horizonint.cloud/api/v2',
		'https://product-int.subscription.horizonint.cloud/api/v2',
		'https://product-int.webapp.horizonint.cloud/api/v1/me/apps',
		'https://login-qa.siemens.com',
		'https://product-int.aods.horizonint.cloud/api/v2',
		'https://btprodeu.piam.eu1.mindsphere.io/oauth/token',
		'https://uss.login-int.siemens.com/userprofile',
		'https://api.complianceassist.horizonint.cloud',
		'https://product-int-dm.device.horizonint.cloud/api/v2'
		],
	WEBSITE_PATH:"dist/compliance-frontend",
	bxAudience: 'https://int.bx.siemens.com',
	maptilerKey: 'dX9mpZcq2D34U3ucPmZS'
};