import { Component } from '@angular/core';

import { SettingsService } from './services/settings.service';
import  { environment } from 'src/environments/environment'
import { Localization } from './services/localization.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  selectedValue = this.settingsService.language;
  cookieUrl: any = "";
  constructor(private settingsService: SettingsService,public localization: Localization) {
    this.cookieUrl = environment.cookieUrl;
  }
}
