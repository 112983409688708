import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
// import { tap } from 'rxjs/operators';
// import { SessionService } from './session.service';
// import { AppAccessService } from './app-access.service';
import { ServiceWorkerAuth } from '@building-x/common-ui-ng';

@Injectable({
  providedIn: "root",
})
export class AuthGuardService implements CanActivate {
  constructor(
    //private readonly sessionService: SessionService,
    private readonly router: Router,
    //private readonly appAccessService: AppAccessService,
	private serviceWorker: ServiceWorkerAuth
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    
    // return this.sessionService.initialized.pipe(
    //   tap((signedIn) => {
    //     if(!signedIn)
    //       this.router.navigate(["/login"]);
    //     else{
    //       this.appAccessService.hasAccess.subscribe((access) => {
    //         if (access.unauthorizedType) {
    //           this.router.navigate(["/main/unauthorized"]);
    //         }
    //       });
    //     }
        
        
    //   })
    // );
  if (await this.serviceWorker.isAuthenticated()) {
    return true;
  } else {
    this.router.navigate(['/login']);
    return false;
  }
  }
}
