import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { SelectOption } from '@simpl/element-ng';
import { constant } from 'src/app/constants/constants';
import { DataMovementService } from 'src/app/services/data-movement-service';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../../environments/environment'

import { SettingsService } from '../../services/settings.service';
export const isAlphaNumeric = (regularExp: RegExp): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string;
    if (value?.includes(' ') || value?.includes('_')) {
      return { forbiddenName: { value } };
    }
    const forbidden = !regularExp.test(value);
    return forbidden ? { forbiddenName: { value } } : null;
  };
};

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
	value = 'fair';
	systemName!: FormControl;
	uuid!: string;
	timeZonesDropdown = new FormControl('');
	availableLanguages = this.settingsService.availableLanguages;
	currentTheme = this.settingsService.theme;
	translateObject!: { [key: string]: { [key: string]: string } };
	mfaDocLink = environment.mfaDocLink;
	mfaStartDate = environment.mfaStartDate;
	mfaEndDate = environment.mfaEndDate;
	warningText = 'LOGIN.WARNING_TEXT';

  constructor(
    private settingsService: SettingsService,
    private dataMovementService: DataMovementService,
  
    private router: Router
  ) {
   
    this.uuid = uuidv4();
    this.systemName = new FormControl('', [
      Validators.required,
      isAlphaNumeric(/^([a-zA-Z0-9_ ]*)$/)
    ]);
  }
// eslint-disable-next-line no-use-before-define
  sortedTimeZone = constant.timezones?.sort((a, b) =>
    (a || '').localeCompare(b || '')
  );

  optionsList: SelectOption[] = this.formSelectObject(this.sortedTimeZone);

  ngOnInit() {
    this.systemName.patchValue('IECompliance');
    this.timeZonesDropdown.patchValue('Etc/UTC');
  }

  /*interface should time zone data*/
  formSelectObject(data: string[]) {
    return data.map((element: any) => {
      return { title: element, id: element };
    });
  }

  selectionChanged(event: any) {
    this.dataMovementService.timeZoneDataLength(event.length);
  }

  saveSettings() {
    this.router.navigate(['/main/home']);
  }
}
