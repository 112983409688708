import {  Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {  ServiceWorkerAuth } from "@building-x/common-ui-ng";
import { BehaviorSubject, Observable , ReplaySubject } from "rxjs";
import { filter, map } from "rxjs/operators";

import { config } from "../../assets/app-config";
//import { environment } from "../../environment/environment";
//import { HttpService } from "./api-services.service";
export interface AccountInfo {
  title?: string;
  image?: string;
}

@Injectable({
  providedIn: "root",
})
export class SessionService {
  //import { HttpService } from "./api-services.service";
  startSession() {
	  throw new Error('Method not implemented.');
  }
  isSessionActive(): any {
	  throw new Error('Method not implemented.');
  }
  endSession() {
	  throw new Error('Method not implemented.');
  }
  setSessionData(sessionData: { userId: number; token: string; }) {
	  throw new Error('Method not implemented.');
  }
  getSessionData(): any {
	  throw new Error('Method not implemented.');
  }
  protected initialized$ = new ReplaySubject<boolean>(1);
  private signSignOnUrl = config.singleSignOnURL;
  private readonly whiteListURLs = ["login", "logout"];
  identityClaimsSubject = new BehaviorSubject<any>(null);
  public onIdentityClaims = this.identityClaimsSubject.asObservable();
  authenticationService: any;
  constructor(
    private routerObj: Router,
    //private httpService: HttpService,
	private serviceWorker: ServiceWorkerAuth
  ) {}


  async initialize(): Promise<void> {
    this.setURLParams();
    try {
    this.serviceWorker.load(this.signSignOnUrl).then(() => {
		console.log("Service worker is loaded ");
		this.serviceWorker.isAuthenticated().then(async (res: any) => {
			console.log('Authenticated',res,this.signSignOnUrl);
			if (res) {
				this.initialized$.next(true);
			} else if (window.location.href.toString().includes(this.signSignOnUrl)) {
			// handle SSO (with new session)
			await this.handleSSO();
			}
			else{
				this.initialized$.next(false);
			}
		});
	});
    } catch (error) {
      console.log('error in isAuthenticated')
    }
  }


  get initialized(): Observable<boolean> {
    return this.initialized$.asObservable();
  }

  requireLogin(): Observable<true> {
    return this.initialized.pipe(
      map<boolean, true>((signedIn) => {
        if (!signedIn) {
          throw new Error("User not authenticated");
        }
        return signedIn;
      })
    );
  }



  async getAccountInfo() {
	const accountData = await this.serviceWorker.getAccountInfo();
	return {
	  title: accountData?.value?.name,
	  image: accountData?.value?.picture
	};
  }
  

  login(): Observable<any> {
    return this.initialized.pipe(map(() => this.serviceWorker.loginButtonClicked));
  }

  logout(): Observable<any> {
    return this.initialized.pipe(map(() => this.serviceWorker.logoutOnMessage));
  }

  public handleSSO() {
	console.log('handleSSO');
    this.serviceWorker.makeAuthRequest().then((res:any) => {
      if (res) {
        // logic to sign in user
        this.initialized.pipe(filter((signedIn) => !!signedIn)).subscribe();
        this.initialized$.next(true);
        this.navigateToParam();
      }
    });
  }

  public setURLParams() {
    let param = window.location.hash;
    if (
      param.length > 1 &&
      !this.whiteListURLs.find((ele) => param.includes(`#/${ele}`))
    ) {
      if (param.includes(`#${this.signSignOnUrl}`)) {
        param = param.replace(`#${this.signSignOnUrl}`, "");
      } else if (param.startsWith("#/")) {
        param = param.substring(2);
      }
      sessionStorage.setItem("entryPointUrl", param);
    }
  }

  public navigateToParam() {
    const param = sessionStorage.getItem("entryPointUrl");
	console.log('param',param);
    if (param) {
      sessionStorage.removeItem("entryPointUrl");
      this.routerObj.navigate([param]);
    } else {
      this.routerObj.navigate(["/"]);
    }
  }
}

@Injectable({
  providedIn: "root",
})
export class MockSessionService extends SessionService {
  override async initialize(): Promise<void> {
    this.initialized$.next(true);
  }
}
