import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ServiceWorkerAuth } from '@building-x/common-ui-ng';
import { SessionService } from "../../services/session.service";

@Component({
  template: "",
})
export class LogoutPageComponent implements OnInit {
  ngOnDestroy() {
	  throw new Error('Method not implemented.');
  }
  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService,
	private serviceWorker: ServiceWorkerAuth
  ) {}

  ngOnInit() {
    this.sessionService.initialized.subscribe((signedIn) => {
      if (signedIn) {
        this.serviceWorker.logoutOnMessage();
      } else {
        this.router.navigate(["/login"]);
      }
    });
  }
}
